.MenuItem {
  display: flex;
  align-items: flex-start;
	border-radius: 0;
}

.titleBlock {
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
  .titleInfo {
    flex: 1 1 auto;
    margin-left: 11px;
    h3{
			padding-top: 2px;
			text-align: left;
      color: var(--primary-color);
      font: var(--font-ml);
      font-weight: var(--font-weight-light);
      letter-spacing: 0.5px;
      line-height: normal;
    }

    p {
			text-align: left;
      color: var(--secondary-color);
      font: var(--font-s);
      font-weight: var(--font-weight-light);
      letter-spacing: 0.25px;
      line-height: 20px;
    }
  }
}

.rightIcon {
  width: 26px;
  height: 26px;
  stroke: var(--primary-color);
  stroke-width: 2.2px;
}