.icon_strip {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 1px;
	background: var(--border-color-2);
	flex-grow: 1;
}

.icon {
	margin: 0.5rem 0;

	svg {
		width: 18px;
		path {
			fill: var(--bg-color-secondary);
		}

    circle {
      fill: var(--text-color-8);
    }
	}
}

.icon:first-child {
	margin-top: -8px;
}

.icon:last-child {
	margin-bottom: -6px;
}

.icon_small {
	margin: 0.4rem 0;

	svg {
		width: 18px;
		fill: var(--inverted-primary-color);
	}
}

.icon_small:first-child {
	margin-top: -8px;

	svg {
		width: 18px;
		fill: var(--inverted-primary-color);
	}
}

.icon_small:last-child {
	margin-bottom: -6px;

	svg {
		width: 18px;
		fill: var(--inverted-primary-color);
	}
}
