.AuthenticationWrapper {
	background-color: var(--bg-color-secondary);
	min-height: 100vh;
	padding: var(--padding-horizontal);
	display: flex;
	flex-direction: column;
}

.AuthMainLogo {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 24px;
	gap: 23px;
	width: 100%;
	max-width: 400px;
	justify-content: center;
	margin: auto;
}

.btnAuthentication {
	padding-top: 24px;
	margin-top: auto;
	margin-bottom: 21px;

	.btn {
		margin-bottom: 5px;
		text-align: center;
		background-color: #212121;
	}
}
