[data-theme="light"] {
	--bg-color-primary: #ffffff;
	--bg-color-secondary: #414042;
  --bg-color-modal: #ffffff;

	// Цвет текста
	--primary-color: #212121;
	--inverted-primary-color: #ffffff;
	--secondary-color: #616161;
	--text-color-1: #212121;
	--text-color-2: #9e9e9e;
	--text-color-3: #212121;
	--text-color-4: #616161;
	--text-color-5: #9e9e9e;
	--text-color-6: #1056bb;
	--text-color-7: #212121;
	--text-color-8: #ffffff;
	--text-color-9: #212121;
	--text-color-10: #91918f;
	--text-color-11: #ffffff;
	--text-color-12: #616161;
	--text-color-13: #212121;
	--text-color-14: #616161;

	// Цвет кнопок
	--bg-button-primary: #ff1940;
  --bg-button-secondary: #212121;
	--bg-button-disabled: #9e9e9e;
	--disabled-text-btn: #ffffff;

	// background colors
	--background-color-1: #ffde17;
	--background-color-2: #414042;

	// Menu
	--bg-menu: #e6f2fe;

	// Border
	--primary-border: #eeeeee;
	--secondary-border: #eeeeee;
	--border-color-1: #212121;
	--border-trip: #ffffff;
	--border-route: #ffffff;
	--border-color-2: #e0e0e0;
	--border-color-3: #e0e0e0;
	--border-color-4: #e0e0e0;
	--border-color-5: #eee;

	// Trip
	--bg-trip: #e6f2fe;
	--bg-fail-trip: #eee;

	// Icon color
	--primary-icon: #212121;

	// Input
	--bg-input: #eeeeee;

	// Modal
	--bg-modal: #ffffff;

	// Select
	--bg-select: #4140421a;

	// Search car loader
	--bg-loader: #e6f2fe;

	// Drivers background
	--bg-drivers-block: #e6f2fe;

	// Review block background
	--bg-review: #e6f2fe;
	--bg-textarea: #ffffff;
	--placeholder: #9e9e9e;

	// colors for animation markers on map
	--shadow-color: rgba(0, 0, 0, 0.3);

	// colors for inputs keyframes animation
	--keyframe-1: #eeeeee;
	--keyframe-2: #ebeced;
	--keyframe-3: #e8e9ea;
	--keyframe-4: #d2d2d2;
	--keyframe-5: #e8e9ea;
	--keyframe-6: #ebeced;

	// gps colors
	--gps-color-active: #1056bb;

	// discount block
	--linear-gradient-blue: linear-gradient(to right, #2b99ff, #3b42f0, #5854ff);

	// main blue color
	--dark-blue-color: #1056bb;

	// header bg
	--wrapper-bg: #1056bb;
}
