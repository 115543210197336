.footer {
	padding: var(--padding-horizontal);
	margin-top: auto;
	margin-bottom: 20px;
	padding-top: 20px;
}

.footer_info {
	display: flex;
}

.blockInfo {
	margin: 0 5px 20px 0;
}

.btn {
	background-color: var(--bg-button-primary);
	margin-top: 10px;
	padding: 0;
	a {
		padding: 15px 0;
		display: flex;
		justify-content: center;
		text-decoration: none;
		font: var(--font-l);
		font-weight: var(--font-weight-bold);
		line-height: 24px;
		letter-spacing: 0.5px;
		color: #fff;
	}
}

.version {
	margin-top: 14px;
	font: var(--font-s);
	font-weight: var(--font-weight-light);
	color: var(--text-color-2);
}

.checkIcon {
	path {
		stroke: var(--secondary-icon);
	}

	circle {
		fill: var(--primary-icon);
		stroke: var(--secondary-icon);

	}
}

.estimateIcon {
	path {
		stroke: var(--primary-icon);
		fill: var(--primary-icon);
	}
}

.bonusIcon {
	path {
		fill: var(--primary-icon);

		&:last-child {
			fill: var(--secondary-icon);
		}
	}
}
