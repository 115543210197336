.LoginWrapper {
	padding: var(--padding-vertical);
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 170px);
}

.title {
	padding-bottom: 20px;
	font: var(--font-xl);
	font-weight: var(--font-weight-bold);
	color: var(--primary-color);
}

.inputPhone {
	display: flex;

	.phone {
		flex: 1;
		margin-left: 3px;
	}
}

.terms {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 30px;

	p {
		font: var(--font-s);
		font-weight: var(--font-weight-light);
		color: var(--secondary-color);
		padding-right: 30px;

		.link {
			color: inherit;
		}
	}
}

.continueBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 60px;
}

.registrationLink {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: auto;

	h3 {
		font: var(--font-s);
		font-weight: var(--font-weight-light);
		color: var(--secondary-color);
		margin-bottom: 8px;
	}

	.link {
		font: var(--font-s);
		font-weight: var(--font-weight-light);
		color: var(--primary-color);
	}
}
