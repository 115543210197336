body {
	width: 100vw;
	overflow-x: hidden;
}

.hidden {
	display: none;
}

.OrderDiscountWrapper {
	position: absolute;
	background: var(--linear-gradient-blue);
	border-radius: 28px 28px 0px 0px;
	box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);
	width: 100%;
	height: 43px;
	top: 0;
	z-index: -1 !important;
	animation: slideIn 0.6s ease forwards;
}

@keyframes slideIn {
	from {
		opacity: 0;
		top: 0;
	}
	to {
		opacity: 1;
		top: -25px;
	}
}

.active {
	opacity: 1;
	top: -25px;
}

.discount {
	display: flex;
	justify-content: center;
	margin-top: 5px;

	p {
		font: var(--font-xs);
		font-weight: var(--font-weight-light);
		color: var(--text-color-8);
		line-height: 16px;
		letter-spacing: 0.4px;
	}
}

.user_geolocation {
	position: fixed;
	z-index: 1;
	top: calc(50% - 70px / 2);
	left: calc(50% - 25px / 2);
	-webkit-transform: perspective(1px) translateZ(0) translateY(-30px) translateX(-8px);
	transform: perspective(1px) translateZ(0) translateY(-30px) translateX(-8px);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: transform;
	transition-property: transform;

	&:before {
		pointer-events: none;
		position: absolute;
		z-index: -1;
		content: "";
		top: 100%;
		left: 5%;
		height: 10px;
		width: 90%;
		opacity: 0;
		background: -webkit-radial-gradient(
			center,
			ellipse,
			rgba(3, 3, 3, 0.25) 0%,
			rgba(0, 0, 0, 0) 80%
		);
		background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0) 80%);
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-property: transform, opacity;
		transition-property: transform, opacity;
	}

	&.animate {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);

		&:before {
			opacity: 1;
			-webkit-transform: translateY(5px);
			transform: translateY(5px);
		}
	}

	img {
		width: 40px;
	}
}

.my_location_c {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
}

.my_location_button {
	right: 16px;
	top: -70px;
	// margin-top: -70px;
	position: absolute;
	border-radius: 30%;
	border: none;
	background: var(--bg-color-primary);
	width: 43px;
	height: 43px;
	outline: none !important;

	svg {
		padding-top: 3px;
		width: 24px;
		height: 24px;

		path {
			fill: var(--primary-color);
		}
	}
}

.down_line {
	display: flex;
	border-radius: 28px 28px 0px 0px;
	background: var(--bg-color-primary);
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 24px;

	svg {
		margin-top: -6px;
	}
}

.downLineSecond {
	display: flex;
	border-radius: 28px 28px 0px 0px;
	background: var(--bg-color-primary);
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 24px;
	box-shadow: 0px -6px 12px -6px rgba(0, 0, 0, 0.18);

	svg {
		margin-top: -6px;
	}
}

.keyboard_open {
	max-height: 110px !important;
	overflow-y: scroll !important;
}

.order_form {
	z-index: 99;
	background: var(--bg-color-primary);
	border-radius: 28px 28px 0px 0px;
	display: flex;
	flex-direction: column;
	padding-left: 16px;
	padding-right: 16px;

	.main_orders_window {
		.orders_item {
			display: flex;
			flex-direction: row;
			background: var(--bg-input);
			height: 48px;
			border-radius: 16px;
			padding-left: 10px;
			padding-right: 10px;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;

			.route_block {
				display: flex;
				flex-direction: row;
				justify-content: center;

				.location_input {
					display: flex;
					flex-direction: row;
					padding: 0px;
					border: none;
					margin-left: 10px;
					font-family: "Roboto";
					font-size: var(--font-size-ml);
					line-height: var(--font-line-ml);
					letter-spacing: 0.15px;
					color: var(--primary-color);
					width: calc(100vw - 111px);
					background: transparent;
					text-overflow: ellipsis;
				}

				input::placeholder {
					color: var(--secondary-color);
				}

				.location_icon {
					svg {
						padding-top: 2px;
					}

					path {
						fill: #1056bb;
						stroke: #1056bb;
					}
				}
			}

			.close_icon {
				display: flex;

				path {
					stroke: var(--primary-color);
				}
			}
		}

		.orders_button {
			display: flex;
			flex-direction: row;
			background: var(--bg-input);
			height: 48px;
			border-radius: 16px;
			padding-left: 10px;
			padding-right: 10px;
			align-items: center;
			margin-bottom: 10px;
			text-decoration: none;

			input::placeholder {
				color: var(--text-color-1);
				font-weight: var(--font-weight-bold);
			}

			.arrow_right_icon {
				padding-top: 2px;

				path {
					stroke: var(--text-color-1);
				}
			}

			.orders_text_button {
				p {
					font-family: "Roboto";
					font-size: var(--font-size-ml);
					line-height: var(--font-line-ml);
					font-weight: var(--font-weight-bold);
					letter-spacing: 0.15px;
					color: var(--text-color-1);
					margin-left: 10px;
					padding: 0px;
				}
			}
		}
	}

	.addresses {
		display: flex;
		flex-direction: column;
		margin-top: 8px;

		.first_address {
			padding-left: 10px;
			display: flex;
			flex-direction: column;
			padding-right: 8px;
			padding-bottom: 10px;
			margin-top: 10px;
			border-bottom: 1px solid var(--bg-input);
		}

		.first_address:last-child {
			border-bottom: none;
		}

		.address_text {
			display: flex;
			flex-direction: row;
			align-items: center;

			.history_icon {
				path {
					fill: var(--primary-color);
				}
			}

			.favorite_addresses {
				display: flex;
				flex-direction: column;
				margin-left: 10px;
			}
		}
	}
}

.location_block {
	// position: absolute;
	// left: 16px;
	// right: 16px;
	// bottom: 40px;
	margin-bottom: 40px;
	margin-left: 16px;
	width: calc(100% - 64px);
	padding-left: 16px;
	padding-right: 16px;
	z-index: 99;
	background: var(--bg-color-primary);
	border-radius: 28px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.location_item {
		padding-top: 24px;
		padding-bottom: 24px;

		.location_change {
			display: flex;
			flex-direction: row;
			background: var(--bg-input);
			height: 48px;

			border-radius: 16px;
			margin-bottom: 16px;
			padding-left: 10px;
			padding-right: 10px;

			.change_item {
				display: flex;
				flex-direction: row;
				height: 48px;
				width: 100%;
				border-radius: 16px;
				justify-content: space-between;
				align-items: center;
				background: var(--bg-input);

				input {
					display: flex;
					padding: 0px;
					border: none;
					width: 100%;
					margin-left: 10px;
					font-family: "Roboto";
					font-size: var(--font-size-ml);
					line-height: var(--font-line-ml);
					letter-spacing: 0.15px;
					color: var(--primary-color);
					background: transparent;
					text-overflow: ellipsis;
				}

				.location_block_icon {
					svg {
						padding-top: 2px;
					}

					path {
						fill: #1056bb;
						stroke: #1056bb;
					}
				}
			}
		}

		a {
			text-decoration: none;
		}

		.location_confirm_button {
			background: var(--bg-button-primary);
			display: flex;
			height: 56px;
			padding: 12px 0px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 10px;
			align-self: stretch;
			border-radius: 16px;
			color: white;
			border: none;
			font-family: "Roboto";
			font-size: var(--font-size-l);
			font-weight: var(--font-weight-bold);
			line-height: 24px;
			letter-spacing: 0.5px;
			width: calc(100vw - 64px);
		}

		.location_not_confirm_button {
			background: var(--bg-button-disabled) !important;
			color: var(--disabled-text-btn) !important;
			pointer-events: none;
			display: flex;
			height: 56px;
			padding: 12px 0px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 10px;
			align-self: stretch;
			border-radius: 16px;
			border: none;
			font-family: "Roboto";
			font-size: var(--font-size-l);
			font-weight: var(--font-weight-bold);
			line-height: 24px;
			letter-spacing: 0.5px;
			width: calc(100vw - 64px);
		}
	}
}

.back_to_button {
	top: 90px;
	left: 16px;
	position: fixed;
	z-index: 991;
	border-radius: 30%;
	border: none;
	background: transparent;
	width: 43px;
	height: 43px;
	color: var(--primary-color);
	outline: none !important;

	.back_to_icon {
		width: 20px;
		height: 20px;
		transform: translate(50%, 50%);

		path {
			stroke: var(--primary-color);
		}
	}
}

.top_block_nav {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	position: fixed;
	margin-top: 70px;
	z-index: 10;

	.navigation_back {
		display: flex;
		align-items: center;
		border-radius: 16px;
		width: calc(100% - 32px);
		background: var(--bg-color-primary);
		height: 44px;
		box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);

		.destination_block {
			display: flex;
			width: calc(100% - 96px);
			height: 36px;
			border-radius: 7px;
			align-items: center;
			justify-content: center;
			background: var(--bg-input);
			gap: 8px;

			.pointIconGray {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 2px;
				margin-left: 10px;
				width: 30px;
				height: 100%;
				overflow: visible;
			}

			svg {
				width: auto;
				height: 16px;
				overflow: visible;
			}

			path {
				fill: var(--text-color-10);
				stroke: var(--text-color-10);
			}

			p {
				font-family: "Roboto";
				font-size: var(--font-size-ml);
				font-weight: var(--font-weight-semilight);
				color: var(--primary-color);
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding-right: 10px;
			}
		}

		.black_icon {
			padding: 0 12px;
			width: 24px;
			height: 24px;
		}

		path {
			stroke: var(--primary-color);
		}

		.backIconWhite {
			display: flex;
			height: 100%;
			align-items: center;
			justify-content: center;

			path {
				stroke: var(--primary-color);
			}
		}
	}
}

.tariffs_form {
	z-index: 99 !important;
	position: relative;
	background: var(--bg-color-primary);
	border-radius: 28px 28px 0px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 40px;

	.items_border {
		display: flex;
	}

	.tariffs_block {
		display: flex;
		width: 100%;
		justify-content: center;
		background: var(--bg-color-primary);
	}

/* 	.tariffsCarousel {
		display: flex;
		width: calc(100% - 32px);
		padding-bottom: 16px;
		padding-top: 3px;
	} */

	.tariffsCarousel {
		display: flex;
		overflow-x: auto;
		scroll-snap-type: x mandatory;
		-webkit-overflow-scrolling: touch; 
		white-space: nowrap;
		width: 100%;
		gap: 8px;
		padding-bottom: 16px;
		padding-top: 3px;
		scrollbar-width: none; 
	}
	
	.tariffsCarousel::-webkit-scrollbar {
		display: none; 
	}
	
	.tariffs {
		scroll-snap-align: start;
		flex: 0 0 auto;
		width: calc(100% / 3 * 5);
	}

	.borderItem {
		display: flex;
		width: 1px;
		height: 100%;
		background: linear-gradient(
			180deg,
			rgba(217, 217, 217, 0) 0%,
			#d9d9d9 52.6%,
			rgba(217, 217, 217, 0) 100%
		);
	}

	.tariffs {
		display: flex;
		width: calc(100% / 3);
		flex: 1;
		justify-content: center;
		border-radius: 16px;
		border: none;
		padding: 0px;
		background: transparent;

		.tariffsItem {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 12px;

			.tariffsName {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				overflow: hidden;

				.carItem {
					width: 83px;
				}

				.tariffBlock {
					text-align: left;
					overflow: hidden;

					.tariff {
						font-family: "Roboto";
						color: var(--primary-color);
						font-size: var(--font-size-m);
						font-weight: var(--font-weight-semilight);
						line-height: var(--font-line-l);
						letter-spacing: 0.1px;
					}

					.tariffTime {
						font-size: var(--font-size-m);
						font-weight: var(--font-weight-semilight);
						line-height: var(--font-line-l);
						color: var(--text-color-2);
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}

			.tariffsCost {
				display: flex;
				margin-top: 8px;

				p {
					font-family: "Roboto";
					color: var(--primary-color);
					font-size: var(--font-size-ml);
					font-weight: var(--font-weight-bold);
					letter-spacing: 0.1px;
				}

				svg {
					height: 12px;
					fill: var(--primary-color);
				}
			}
		}
	}

	// Нет доступных машин
	.unavailableCar {
		.tariffsItem {
			.tariffsName {
				.carItem {
					margin-top: 15px;
				}
			}
		}
	}

	.reject_block {
		display: flex;
		flex-direction: column;
		margin-top: 8px;

		button {
			background: transparent;
			display: flex;
			height: 52px;
			padding: 12px 0px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 10px;
			align-self: stretch;
			border-radius: 16px;
			color: var(--primary-color);
			border: none;
			font-family: "Roboto";
			font-size: var(--font-size-l);
			letter-spacing: 0.5px;
			width: calc(100vw - 32px);
			border: 1px solid var(--border-color-1);
		}
	}

	.confirm_block {
		display: flex;
		flex-direction: column;
		width: calc(100% - 32px);
		height: 54px;

		button {
			height: 100%;
		}
	}
}

.circlePreferences {
	display: flex;
	width: 23px;
	height: 23px;
	justify-content: center;
	align-items: center;
	color: var(--primary-color);
	font-family: "Roboto";
	font-size: var(--font-size-s);
	font-weight: var(--font-weight-bold);
	border-radius: 100px;
	border: 1px solid var(--gray-day, #e0e0e0);
	margin-top: -1px;
}

.circlePreferencesDark {
	display: flex;
	width: 23px;
	height: 23px;
	justify-content: center;
	align-items: center;
	color: var(--primary-color);
	font-family: "Roboto";
	font-size: var(--font-size-s);
	font-weight: var(--font-weight-bold);
	border-radius: 100px;
	border: 1px solid var(--lt-gray-dark-mode, #262626);
	background: var(--black-day, #212121);
	margin-top: -1px;
}

.last_item {
	border-bottom: none !important;
}

.activeTariff {
	border: 1px solid #414042;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
}

.activeTariffDark {
	// border: 1px solid #414042 !important;
	box-shadow: 0px 0px 3px 0px #414042;
}

.borderItemDark {
	display: flex;
	width: 2.5px !important;
	height: 100%;
	background: linear-gradient(
		180deg,
		rgba(33, 33, 33, 0) 0%,
		#212121 52.6%,
		rgba(33, 33, 33, 0) 100%
	) !important;
}

.OrderExpectWrapper {
	position: relative;
}

.search_car_form {
	position: relative;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: var(--bg-color-primary);
	border-radius: 28px 28px 0px 0px;
	display: flex;
	flex-direction: column;
	padding-left: 16px;
	padding-right: 16px;
	align-items: center;

	.main_search_block {
		display: flex;
		flex-direction: column;
		align-items: center;

		.search_car_text {
			font-family: "Roboto";
			font-size: var(--font-size-xl);
			line-height: var(--font-line-xl);
			font-weight: var(--font-weight-bold);
			color: var(--primary-color);
			letter-spacing: 0.1px;
		}

		.car_class_search {
			font-family: "Roboto";
			font-size: var(--font-size-ml);
			line-height: 18.75px;
			color: var(--text-color-1);
			letter-spacing: 0.1px;
			padding-top: 4px;
		}
	}

	.details_search {
		width: 100vw;
		height: 300px;
		overflow-y: scroll;
		display: flex;
		justify-content: center;
	}

	.details_items {
		display: flex;
		flex-direction: column;
		width: calc(100vw - 32px);

		.order_costs {
			display: flex;
			width: calc(100vw - 32px);
			justify-content: space-between;
			align-items: center;
			margin-top: 30px;
			padding-bottom: 15px;
			border-bottom: 1px solid var(--bg-input);

			p {
				font-family: "Roboto";
				font-size: var(--font-size-xl);
				line-height: var(--font-line-xl);
				font-weight: var(--font-weight-bold);
				color: var(--primary-color);
				letter-spacing: 0.1px;
			}

			.price_section {
				display: flex;
				align-items: center;

				svg {
					margin-top: -1px;
					height: 18px;
					fill: var(--primary-color);
				}
			}
		}

		.type_of_payment {
			display: flex;
			width: calc(100vw - 32px);
			justify-content: space-between;
			align-items: center;
			margin-top: 16px;
			padding-bottom: 12px;
			border-bottom: 1px solid var(--bg-input);

			p {
				font-family: "Roboto";
				font-size: var(--font-size-ml);
				color: var(--primary-color);
				letter-spacing: 0.5px;
			}

			.type_section {
				display: flex;
				align-items: center;

				.icon_type {
					margin-top: -1px;

					path:first-child {
						fill: var(--primary-color);
					}

					path:nth-child(2) {
						stroke: var(--primary-color);
					}
				}

				p {
					font-family: "Roboto";
					font-size: var(--font-size-ml);
					color: var(--primary-color);
					letter-spacing: 0.5px;
					padding-left: 8px;
				}
			}
		}

		.order_path {
			display: flex;
			width: calc(100vw - 32px);
			justify-content: flex-start;
			align-items: center;
			margin-top: 30px;

			p {
				font-family: "Roboto";
				font-size: var(--font-size-xl);
				line-height: var(--font-line-xl);
				font-weight: var(--font-weight-bold);
				color: var(--primary-color);
				letter-spacing: 0.1px;
			}
		}

		.order_path_details {
			display: flex;
			flex-direction: row;
			margin-top: 34px;
		}

		.path_icons {
			display: flex;
			flex-direction: column;
			padding-left: 8px;
			align-items: center;
		}

		.path_route {
			display: flex;
			flex-direction: column;
			padding-left: 20px;
			width: 100%;
			margin-top: -6px;
		}

		.path_single_item {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid var(--bg-input);
			padding-bottom: 18px;
			padding-top: 18px;
		}

		.path_single_item:last-child {
			border-bottom: 0px;
			padding-bottom: 0px;
		}

		.path_icons {
			display: flex;
			flex-direction: column;
			padding-left: 8px;
			align-items: center;
			align-self: stretch;
			/* Растянуть блок по вертикали на всю высоту */
		}

		.path_route {
			display: flex;
			flex-direction: column;
			padding-left: 20px;
			width: 100%;
			margin-top: -6px;
		}

		.path_single_item {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid var(--bg-input);
			padding-bottom: 18px;
			padding-top: 18px;
			flex-grow: 1;
		}

		.path_single_item:first-child {
			padding-top: 0px;
		}

		.path_single_item:last-child {
			border-bottom: 0px;
			padding-bottom: 0px;
		}
	}

	.reject_btn {
		background: transparent;
		display: flex;
		height: 56px;
		padding: 12px 0px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-self: stretch;
		color: #ee392e;
		border: none;
		font-family: "Roboto";
		font-size: var(--font-size-l);
		font-weight: var(--font-weight-bold);
		line-height: 24px;
		letter-spacing: 0.5px;
		width: calc(100vw - 32px);
		margin-top: 24px;
		border-top: 1px solid var(--bg-input);
		border-bottom: 1px solid var(--bg-input);
	}

	.loader {
		margin-top: 24px;
		margin-bottom: 24px;
	}

	.running_banner {
		width: calc(100vw - 32px);
		height: 8px;
		background-color: var(--bg-loader);
		position: relative;
		border-radius: 100px;
	}

	.filler {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		border-radius: 100px;
		background-color: #1056bb;
		animation: fillAnimation 10s linear infinite;
	}

	.icon {
		position: absolute;
		left: 0;
		animation: fillAnimationIcon 10s linear infinite;
		margin-top: -10px;
	}

	@keyframes fillAnimation {
		to {
			width: calc(100vw - 32px);
		}
	}

	@keyframes fillAnimationIcon {
		to {
			left: calc(100vw - 70px);
		}
	}
}

.main_text {
	font-family: "Roboto";
	font-size: var(--font-size-ml);
	color: var(--primary-color);
	letter-spacing: 0.5px;
}

.secondary_text {
	font-family: "Roboto";
	font-size: var(--font-size-s);
	color: var(--text-color-2);
	letter-spacing: 0.25px;
	padding-top: 8px;
}

.icon_payment {
	path:first-child {
		fill: var(--primary-color);
	}

	path:nth-child(2) {
		stroke: var(--primary-color);
	}
}

.priceBlock {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.link_btn {
	display: flex;
	height: 54px;
	align-items: center;
	justify-content: space-between;
	padding-top: 6px;
	padding-bottom: 8px;
	text-decoration: none;
	width: 100%;
	border-bottom: 1px solid var(--bg-input);

	.link_name {
		display: flex;
		height: 54px;
		align-items: center;

		svg {
			path {
				fill: var(--primary-color);
			}
		}

		p {
			font-family: "Roboto";
			font-size: var(--font-size-ml);
			letter-spacing: 0.15px;
			color: var(--primary-color);
			padding-left: 16px;
		}
	}

	svg {
		path {
			stroke: var(--primary-color);
		}
	}
}

.reject_btn_second {
	background: transparent;
	display: flex;
	padding: 12px 0px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	color: #ee392e;
	border: none;
	font-family: "Roboto";
	font-size: var(--font-size-l);
	font-weight: var(--font-weight-bold);
	line-height: 24px;
	letter-spacing: 0.5px;
	width: calc(100vw - 32px);
	margin-top: 8px;
	padding-bottom: 40px !important;
}

.driver_info_block {
	display: flex;
	flex-direction: row;
	width: calc(100vw - 32px);

	.about_driver {
		display: flex;
		flex-direction: row;
		background: var(--bg-drivers-block);
		border-radius: 28px;
		width: 100%;
		align-items: center;
		justify-content: center;

		.drivers_block_cont {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-left: 16px;
			position: relative;

			@media screen and (max-width: 330px) {
				padding-left: 4px;
			}

			.drivers_photo {
				width: 64px;
				height: 64px;
				align-items: center;
				display: flex;
				justify-content: center;
				background: #1056bb;
				border-radius: 100%;

				@media screen and (max-width: 315px) {
					width: 50px;
					height: 50px;
				}
			}

			img {
				width: 64px;
				height: 64px;
				align-items: center;
				display: flex;
				justify-content: center;
				background: transparent;
				border-radius: 100%;
				border: none;

				@media screen and (max-width: 315px) {
					width: 50px;
					height: 50px;
				}
			}

			.drivers_rating {
				position: absolute;
				bottom: 0;
				display: flex;
				width: 53px;
				height: 18px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100px;
				background: white;
				margin-bottom: -5px;

				@media screen and (max-width: 300px) {
					width: 43px;

					svg {
						width: 13px;
					}
				}

				p {
					font-family: "Roboto";
					font-size: var(--font-size-s);
					font-weight: var(--font-weight-bold);
					color: var(--inverted-color);
					letter-spacing: 0.5px;
					max-width: 100px;
					overflow: hidden;
					padding-left: 3px;

					@media screen and (max-width: 300px) {
						font-size: var(--font-size-xs);
					}
				}
			}
		}

		.drivers_info_main {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding-left: 8px;

			.drivers_name {
				font-family: "Roboto";
				font-size: var(--font-size-l);
				font-weight: var(--font-weight-bold);
				color: var(--primary-color);
				letter-spacing: 0.5px;
				max-width: 120px;
				overflow: hidden;

				@media screen and (max-width: 300px) {
					font-size: var(--font-size-s);
				}
			}

			.drivers_trips {
				font-family: "Roboto";
				font-size: var(--font-size-s);
				color: #8391a1;
				letter-spacing: 0.25px;
				padding-top: 8px;

				@media screen and (max-width: 300px) {
					font-size: var(--font-size-xs);
				}
			}
		}
	}

	.call_driver {
		background: var(--bg-drivers-block);
		border-radius: 28px;
		padding: 24px;
		margin-left: 8px;

		@media screen and (max-width: 280px) {
			padding: 16px;
		}

		.phone_bg {
			display: flex;
			width: 48px;
			height: 48px;
			align-items: center;
			justify-content: center;
			background: #1db960;
			border-radius: 100%;
		}
	}
}

.car_class_info_block {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;
	margin-top: 16px;

	.car_name_with_class {
		display: flex;
		align-items: center;

		@media screen and (max-width: 300px) {
			flex-direction: column;
		}

		p {
			font-family: "Roboto";
			font-size: var(--font-size-l);
			font-weight: var(--font-weight-bold);
			letter-spacing: 0.15px;
			color: var(--text-color-4);
		}

		.car_circle {
			background: var(--text-color-4);
			width: 4px;
			height: 4px;
			border-radius: 100%;
			margin-left: 6px;
			margin-right: 6px;

			@media screen and (max-width: 300px) {
				display: none;
			}
		}
	}

	.car_number {
		font-family: "Roboto";
		font-size: var(--font-size-xl);
		letter-spacing: 0.15px;
		color: var(--text-color-4);
		background: var(--background-color-1);
		color: var(--text-color-3);
		padding: 9px 16px 9px 16px;
		border-radius: 12px;
		text-transform: uppercase;
		margin-top: 10px;
	}
}

.header_order_block {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 16px;
	border-bottom: 1px solid var(--bg-input);

	.current_address_block {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 15px;

		svg {
			width: 18px;
			path {
				fill: var(--bg-color-secondary);
			}
		}

		p {
			font-family: "Roboto";
			font-size: var(--font-size-ml);
			color: var(--text-color-7);
			letter-spacing: 0.5px;
			padding-left: 8px;
		}
	}
}

.header_main_text {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	svg {
		padding-top: 8px;

		path:nth-child(1) {
			fill: var(--primary-color);
		}

		path:nth-child(2) {
			fill: var(--inverted-primary-color);
			stroke: var(--inverted-primary-color);
		}
	}

	p {
		font-family: "Roboto";
		font-size: var(--font-size-xl);
		letter-spacing: 0.15px;
		color: var(--primary-color);
		padding-top: 8px;
		padding-bottom: 8px;
	}
}

.order_timer {
	display: flex;
	width: calc(100vw - 32px);
	align-items: center;
	justify-content: center;
	padding-top: 24px;
	border-top: 1px solid var(--bg-input);
	padding-bottom: 24px;
	border-bottom: 1px solid var(--bg-input);

	.timer_single_item {
		display: flex;
		flex-direction: column;
		width: 50%;
		height: 65px;
		align-items: flex-start;
		padding-top: 8px;
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 8px;
		justify-content: space-between;
		background: var(--bg-drivers-block);
		border-radius: 16px;

		.add_cost_text {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-between;

			svg {
				path {
					stroke: var(--primary-color);
				}
			}
		}

		.add_cost_timer {
			display: flex;

			svg {
				width: 12px;

				path {
					fill: var(--primary-color);
				}
			}
		}
	}
}

.timer_light_text {
	font-family: "Roboto";
	font-size: var(--font-size-s);
	letter-spacing: 0.25px;
	color: var(--primary-color);
}

.timerBoldText {
	font-family: "Roboto";
	font-size: var(--font-size-xl);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.1px;
	color: var(--primary-color);
}

.timerEndText {
	font-family: "Roboto";
	font-size: var(--font-size-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.1px;
	color: var(--primary-color);
}

.order_costs {
	display: flex;
	width: calc(100vw - 32px);
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
	padding-bottom: 15px;
	border-bottom: 1px solid var(--bg-input);

	p {
		font-family: "Roboto";
		font-size: var(--font-size-xl);
		line-height: var(--font-line-xl);
		font-weight: var(--font-weight-bold);
		color: var(--primary-color);
		letter-spacing: 0.1px;
	}

	.price_section {
		display: flex;
		align-items: center;

		svg {
			margin-top: -1px;
			height: 18px;
			fill: var(--primary-color);
		}
	}
}

.type_of_payment {
	display: flex;
	width: calc(100vw - 32px);
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
	padding-bottom: 12px;
	border-bottom: 1px solid var(--bg-input);

	p {
		font-family: "Roboto";
		font-size: var(--font-size-ml);
		color: var(--primary-color);
		letter-spacing: 0.5px;
	}

	.type_section {
		display: flex;
		align-items: center;

		.icon_type {
			margin-top: -1px;

			path:first-child {
				fill: var(--primary-color);
			}

			path:nth-child(2) {
				stroke: var(--primary-color);
			}
		}

		p {
			font-family: "Roboto";
			font-size: var(--font-size-ml);
			color: var(--primary-color);
			letter-spacing: 0.5px;
			padding-left: 8px;
		}
	}
}

.add_cost_order_price {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: calc(100vw - 32px);
	padding-bottom: 16px;
	border-bottom: 1px solid var(--bg-input);

	.add_cost_order_item {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		padding-top: 16px;

		p {
			font-family: "Roboto";
			font-size: var(--font-size-ml);
			color: var(--primary-color);
			letter-spacing: 0.5px;
		}

		.add_cost_order_item_price {
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				font-family: "Roboto";
				font-size: var(--font-size-l);
				font-weight: var(--font-weight-bold);
				color: var(--primary-color);
				letter-spacing: 0.1px;
			}

			svg {
				margin-top: -1px;
				height: 13px;
				fill: var(--primary-color);
			}
		}
	}
}

.disabled_icon {
	path {
		stroke: #8391a1 !important;
	}
}

.order_main_safe {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	padding-bottom: 20px;
}

.review_block {
	position: absolute;
	top: 0;
	background: rgba(33, 33, 33, 0.7);
	width: 100vw;
	height: 100vh;
	z-index: 99999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.review_item {
	position: relative;
	display: flex;
	flex-direction: column;
	background: var(--bg-modal);
	width: calc(100vw - 48px);
	max-width: 400px;
	height: auto;
	margin: 0 16px;
	border-radius: 28px;

	.leave_review {
		font-family: "Roboto";
		font-size: var(--font-size-xl);
		line-height: var(--font-line-xl);
		font-weight: var(--font-weight-bold);
		color: var(--primary-color);
		letter-spacing: 0.1px;
		padding-top: 24px;
		text-align: center;
	}

	.stars_review {
		display: flex;
		flex-direction: column;
		padding: 24px 16px;
		border-radius: 28px;
		background: var(--bg-review);
		margin-top: 16px;
		width: calc(100% - 64px);
		margin-left: 16px;
		margin-right: 16px;
		align-items: center;
		justify-content: center;

		.evaluate_trip {
			font-family: "Roboto";
			font-size: var(--font-size-ml);
			color: var(--primary-color);
			letter-spacing: 0.1px;
		}

		.anonim_review {
			font-family: "Roboto";
			font-size: var(--font-size-xs);
			color: var(--text-color-5);
			letter-spacing: 0.1px;
			padding-top: 10px;
		}

		textarea {
			background: var(--bg-textarea);
			border: none;
			width: calc(100% - 32px);
			max-height: 100px;
			color: var(--primary-color);
			border-radius: 18px;
			font-family: "Roboto";
			font-size: var(--font-size-ml);
			letter-spacing: 0.1px;
			padding: 15px 17px;
			margin-top: 18px;
			resize: vertical;
		}

		textarea::placeholder {
			color: var(--placeholder);
			font-family: "Roboto";
			font-size: var(--font-size-ml);
			letter-spacing: 0.1px;
		}
	}

	.confirm_review {
		background: var(--bg-button-primary);
		display: flex;
		height: 56px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-self: stretch;
		border-radius: 16px;
		color: white;
		border: none;
		font-family: "Roboto";
		font-size: var(--font-size-l);
		line-height: var(--font-line-l);
		font-weight: var(--font-weight-bold);
		letter-spacing: 0.5px;
		width: calc(100% - 32px);
		margin-top: 32px;
		margin-left: 16px;
		margin-bottom: 8px;
	}

	.no_confirm_review {
		background: var(--bg-button-primary);
		display: flex;
		height: 56px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-self: stretch;
		border-radius: 16px;
		color: white;
		border: none;
		font-family: "Roboto";
		font-size: var(--font-size-l);
		line-height: var(--font-line-l);
		font-weight: var(--font-weight-bold);
		letter-spacing: 0.5px;
		width: calc(100% - 32px);
		margin-left: 16px;
		margin-bottom: 24px;
	}
}

.disabledButton {
	background: var(--bg-button-disabled) !important;
	color: var(--disabled-text-btn) !important;
	pointer-events: none;
}

.location_input {
	display: flex;
	flex-direction: row;
	padding: 0px;
	border: none;
	margin-left: 10px;
	font-family: "Roboto";
	font-size: var(--font-size-ml);
	line-height: var(--font-line-ml);
	letter-spacing: 0.15px;
	color: var(--primary-color);
	width: calc(100vw - 115px);
	background: transparent;
	text-overflow: ellipsis;
}

input::placeholder {
	color: var(--secondary-color);
}

.location_icon {
	svg {
		padding-top: 2px;
	}

	path {
		fill: #1056bb;
		stroke: #1056bb;
	}
}

.location_icon_empty {
	svg {
		padding-top: 2px;
	}

	path {
		fill: var(--text-color-1);
		stroke: var(--text-color-1);
	}
}

.icon_users {
	width: 24px;

	path {
		fill: #1db960 !important;
	}
}
