[data-theme="dark"] {
	--bg-color-primary: #0d0d0d;
	--bg-color-secondary: #414042;
	--bg-color-modal: #2d4053;

	// Цвет текста
	--primary-color: #ffffff;
	--inverted-primary-color: #212121;
	--secondary-color: #a8a8a8;
	--text-color-1: #8391a1;
	--text-color-2: #414042;
	--text-color-3: #1f2d3e;
	--text-color-4: #ffffff;
	--text-color-5: #8391a1;
	--text-color-6: #ffffff;
	--text-color-7: #8391a1;
	--text-color-8: #ffffff;
	--text-color-9: #616161;
	--text-color-10: #ffffff;
	--text-color-11: #616161;
	--text-color-12: #414042;
	--text-color-13: #616161;
	--text-color-14: #a8a8a8;

	// Цвет кнопок
	--bg-button-primary: #ff1940;
	--bg-button-secondary: #ff1940;
	--bg-button-disabled: #3c3d40;
	--disabled-text-btn: #a8a8a8;

	// background colors
	--background-color-1: #ffde17;

	// Menu
	--bg-menu: #414042;

	// Border
	--primary-border: #8391a1;
	--secondary-border: #2d4053;
	--border-color-1: #657180;
	--border-trip: #314563;
	--border-route: #8391a1;
	--border-color-2: #657180;
	--border-color-3: #313d66;
	--border-color-4: #2d4053;
	--border-color-5: #212121;

	// Trip
	--bg-trip: #414042;
	--bg-fail-trip: #262626;

	// Icon color
	--primary-icon: #a8a8a8;

	// Input
	--bg-input: #212121;

	// Modal
	--bg-modal: #0d0d0d;

	// Switch
	--bg-select: #1e1e1e;

	// Search car loader
	--bg-loader: #2f4153;

	// Drivers background
	--bg-drivers-block: #233f5b;

	// Review block background
	--bg-review: #1f2f3e;
	--bg-textarea: #657180;
	--placeholder: #a8a8a8;

	// colors for animation markers on map
	--shadow-color: rgba(254, 254, 254, 0.3);

	// colors for inputs keyframes animation
	--keyframe-1: #2d4053;
	--keyframe-2: #384b61;
	--keyframe-3: #3e546c;
	--keyframe-4: #3e546c;
	--keyframe-5: #384b61;
	--keyframe-6: #2d4053;

	// gps colors
	--gps-color-active: #1056bb;

	// discount block
	--linear-gradient-blue: linear-gradient(to right, #2b99ff, #3b42f0, #5854ff);

	// main blue color
	--dark-blue-color: #1056bb;

	// header bg
	--wrapper-bg: #171a1f;
}
