.travel_time_driver {
  p {
    font-family: "Roboto";
    text-align: center;
    font: var(--font-xl);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.15px;
    color: var(--primary-color);
    padding-bottom: 8px;
  }
}

.car_close {
  p {
    color: var(--primary-color);
  }
}
