.placeOnMap {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    border-bottom: 1px solid var(--bg-input);
    padding: 8px 0;
    opacity: 0;
    animation: slideIn 0.5s ease-in-out 0.5s forwards;

    svg {
        padding-left: 12px;
    }

    path {
        stroke: var(--primary-color);
    }

    circle {
        stroke: var(--primary-color);
    }

    &:last-child {
        border-bottom: none;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.text_block {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    width: calc(100% - 60px);

    .main_text {
        font: var(--font-s);
        color: var(--primary-color);
        letter-spacing: 0.15px;
        padding-top: 1px;
    }
}

.scroll_suggestions {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 323px);
    overflow-y: scroll;
    padding-bottom: 100px;

    path {
        fill: var(--primary-color);
    }
}

.scroll_suggestions_big {
    height: calc(100vh - 380px);
}