.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  min-height: 90px;
  background-color: var(--bg-color-secondary);
  font: var(--font-l);
  padding: var(--padding-horizontal);
  padding-bottom: 30px;
  z-index: -1;
}

.content {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  cursor: pointer;
}

.icon {
  stroke: white;
}

.title {
  padding-left: 17px;
  color: #fff;
  font: var(--font-l);
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.5px;
  font-weight: 500;
}

.title_without {
  padding-left: 0px !important;
}