.arrow_back {
	display: flex;
	width: 44px;
	height: 44px;
	align-items: center;
	justify-content: center;
	background: var(--bg-color-primary);
	margin-top: 70px;
	margin-left: 16px;
	position: fixed;
	z-index: 10;
	border-radius: 30%;
	box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);

	svg {
		margin-left: -1px;
		width: 20px;
		height: 20px;
	}

	path {
		stroke: var(--primary-color);
	}
}

.MapPreferencesWrapper {
	height: max-content;
	background: var(--bg-color-primary);
	position: relative;
	box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.18);
	border-radius: 28px 28px 0px 0px;
	display: flex;
	flex-direction: column;
	z-index: 9999;
	animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(50px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.add_comment_block {
	display: flex;
	flex-direction: column;

	.comment_area:hover {
		border: 1px solid var(--border-color-1);
	}

	.comment_area {
		position: relative;
		display: flex;
		margin-top: -5px;
		border: 1px solid var(--secondary-border);
		border-radius: 18px;
		height: 96px;
		margin-bottom: 40px;

		textarea {
			background: transparent;
			border: none;
			padding: 16px;
			height: 46px;
			width: 100%;
			color: var(--primary-color);
			font-family: "Roboto";
			font-size: var(--font-size-ml);
			letter-spacing: 0.15px;
			resize: none;
		}

		.text_counter {
			position: absolute;
			margin-bottom: -25px;
			bottom: 0;
			margin-right: 16px;
			right: 0;
			color: #657180;
			font-family: "Roboto";
			font-size: var(--font-size-xs);
			line-height: var(--font-line-xs);
			letter-spacing: 0.4px;
		}

		textarea::placeholder {
			color: var(--secondary-color);
		}
	}

	.comment_services {
		display: flex;
		flex-direction: column;
		margin-top: 24px;
		width: 100vw;
		margin-left: -16px;

		.comment_item {
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
			width: 100vw;
			background: transparent;

			.comment_single_item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: calc(100vw - 32px);
				height: 70px;
				border-bottom: 1px solid var(--bg-input);

				p {
					font-family: "Roboto";
					font-size: var(--font-size-ml);
					line-height: var(--font-line-ml);
					letter-spacing: 0.15px;
					color: var(--primary-color);
					font-weight: 400 !important;
				}

				.check_icon {
					margin-left: 24px;

					svg {
						width: 24px;
						height: 24px;

						path {
							stroke: #1056bb;
						}
					}
				}
			}
		}
	}

	.confirm_comment {
		background: var(--bg-button-primary);
		display: flex;
		position: fixed;
		bottom: 0;
		height: 56px;
		padding: 12px 0px;
		margin-bottom: 24px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-self: stretch;
		border-radius: 16px;
		color: white;
		border: none;
		font-family: Roboto;
		font-size: 18px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0.5px;
		width: calc(100vw - 32px);
	}
}

.preferencesBlock {
	display: flex;
	flex-direction: column;
	padding: 0 16px;

	a {
		text-decoration: none;
	}

	.preferences_item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 54px;
		margin-top: 16px;

		.chevron_right {
			display: flex;

			svg {
				padding-top: 2px;
				margin-right: -4px;
			}

			path {
				stroke: var(--primary-color);
			}
		}
	}

	.headerBlock {
		display: flex;
		flex-direction: column;
		padding-top: 25px;

		.firstRoute {
			display: flex;
			align-items: center;
			padding-bottom: 30px;
			gap: 10px;

			p {
				color: var(--primary-color);
				font: var(--font-ml);
				letter-spacing: 0.15px;
				padding: 0;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
				overflow: hidden;
			}
			svg {
				path {
					fill: var(--primary-color);
				}
                circle {
					fill: var(--primary-color);
				}
			}
		}
	}

	.preferencesServices {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
		width: 100vw;
		margin-left: -16px;

		.preferencesServicesBtn {
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
			width: 100vw;
			background: transparent;

			.preferencesServicesItem {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: calc(100vw - 32px);
				height: 56px;
				border-top: 1px solid var(--bg-input);

				p {
					font-family: "Roboto";
					font-size: var(--font-size-ml);
					line-height: var(--font-line-ml);
					letter-spacing: 0.15px;
					color: var(--primary-color);
					font-weight: 400 !important;
				}

				.preferencesPrice {
					display: flex;
					align-items: center;

					p {
						font-family: "Roboto";
						font-size: var(--font-size-ml);
						line-height: var(--font-line-ml);
						letter-spacing: 0.15px;
						color: var(--text-color-13);
					}

					.sum {
						display: flex;
						margin-right: 48px;
						align-items: center;

						svg {
							width: 9px;
							fill: var(--primary-color);
						}
					}

					.check {
						margin-left: 24px;

						svg {
							width: 24px;
							height: 24px;

							path {
								stroke: var(--primary-color);
							}
						}
					}
				}
			}
		}
	}
}

.sumActive {
	margin-right: 0px !important;
}

.last_item {
	border-bottom: none !important;
}

button {
	border: none;
}

.confirmBlock {
	margin-top: auto;
	padding-bottom: 40px;
}
