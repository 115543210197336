.Button {
	cursor: pointer;
	color: var(--primary-color);
	border: none;
	border-radius: 16px;
	padding: 15px 0;
}

.background {
	background: var(--bg-button-primary);
	border: none;
	color: #fff;
}

.select {
	background-color: var(--bg-input);
	color: var(--secondary-color);
	font-weight: var(--font-weight-light);
	display: flex;
	justify-content: space-between;
	padding: 15px 15px;
}

.disabled {
	background-color: var(--bg-button-disabled);
	color: var(--disabled-text-btn);
}

.clear {
	padding: 0;
	border: none;
	background: none;
	outline: none;
	line-height: 20px;
	letter-spacing: 0.25px;
}

.success {
	padding: 4px 10px 5px 10px;
	background-color: var(--bg-button-primary);
	color: #ffffff;
	letter-spacing: 0.5px;
}

.outline {
  background: none;
  border: 1px solid var(--primary-color);
}

.size_s {
	font: var(--font-s);
	font-weight: 500;
}

.size_m {
	font: var(--font-m);
	font-weight: 500;
}

.size_l {
	font: var(--font-l);
	font-weight: 500;
}

.size_xl {
	font: var(--font-xl);
	font-weight: 500;
}

.fullWidth {
	width: 100%;
	font-weight: 500;
}

.circularProgress {
	margin-top: -3px;
}
