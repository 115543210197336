$zIndex-paid-wait: 1;

.PaidWaitWrapper {
	position: absolute;
	background-color: var(--bg-color-secondary);
	color: var(--text-color-8);
	border-radius: 28px 28px 0px 0px;
	width: 100%;
	height: 60px;
	top: -26px;
	z-index: $zIndex-paid-wait;
}

.paidWait {
	background-color: #ee392e;
}

.containerTime {
	display: flex;
	justify-content: center;
	margin-top: 6px;

	p {
		font: var(--font-xs);
		font-weight: var(--font-weight-light);
		color: var(--text-color-8);
		line-height: 16px;
		letter-spacing: 0.4px;
	}
}
