.selectContainer {
	position: relative;
}

.selectButton {
	padding: 10px;
	background-color: inherit;
	cursor: pointer;
	width: 150px;
}

.selectOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	z-index: var(--overlay-z-index);
}

.search {
	margin: 2px 13px 9px 13px;
	position: relative;

	.searchInput {
		border-radius: 12px;
		padding: 8px 0;
		font: var(--font-s);
		font-weight: var(--font-weight-light);
	}

	.searchIcon {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		margin-right: 12px; /* Добавьте отступ между иконкой и инпутом */
	}
}

.selectDropdown {
	background-color: var(--bg-color-primary);
	transition: background-color 400ms ease;
	width: 100%;
	min-height: calc(100% - 90px);
	overflow-y: auto;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	animation: slideIn 0.2s ease-out;
	display: flex;
	flex-direction: column;
}

.small_select {
	min-height: calc(100% - 50px);
}

.selectOption {
	display: flex;
	cursor: pointer;
}

.content {
	width: 100%;
	border-bottom: 1px solid var(--bg-input);
	transition: border-bottom 300ms ease;
	padding: 24px 0;
	margin: 0 13px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	svg {
		path {
			stroke: var(--primary-color);
		}
	}
}

.title {
	font: var(--font-ml);
	font-weight: var(--font-weight-light);
	color: var(--primary-color);
}

.activeOption {
	border-radius: 15px;
	background-color: var(--bg-select);
	transition: background-color 500ms ease;
}

.btnBlock {
	display: flex;
	justify-content: center;
	margin-top: auto;

	.saveBtn {
		margin-bottom: 24px;
		width: 92%;
	}
}

@keyframes slideIn {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}
